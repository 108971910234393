import React from 'react'
import { Button, Col, Flex, Image, Row, Typography } from 'antd'

const { Title, Text } = Typography
const HelpComponent = ({clientname}) => {
  return (
    <Row gutter={[16,16]}>
        <Col span={24}>
            <Title level={5} className="fw-500">COMMENTS</Title>
        </Col>
        <Col span={24}>
            <Flex gap={5} align="center">
                <Image src="/assets/images/av-1.png" preview={false} width={45} height={45} style={{borderRadius: 50}} />
                <Text>
                    <strong>{clientname} </strong>
                    added a comment 2 hours ago
                </Text>
            </Flex>
        </Col>
        <Col span={24}>
            <Flex justify="space-between" className="py-2 px-2 bg-light-gray" align="center">
                <Text className="fs-13 fw-400">
                    The problem is not solved till yet.
                </Text>
                <Button className='border0 bg-transparent text-gray fs-12'>
                    <img src="/assets/icons/reply.png" width={15} alt="" />
                    replay
                </Button>
            </Flex>
        </Col>
    </Row>
  )
}

export {HelpComponent}