export const deviceData = [
    {
        key:'1',
        devicetype:'NV910D',
        devicename:'Device 1',
        devicenumber:'INV-993JK',
        clientname:'Susan Sumanggih',
        vehiclenumber:'ABC-999DE',
        status:'Active',
        regdate:'2021-01-01',
    },
    {
        key:'2',
        devicetype:'MV810G',
        devicename:'Device 2',
        devicenumber:'INV-993JK',
        clientname:'Lukman Farhan',
        vehiclenumber:'ABC-999DE',
        status:'Inactive',
        regdate:'2025-12-12',
    },
    {
        key:'3',
        devicetype:'HV810K',
        devicename:'Device 3',
        devicenumber:'INV-993JK',
        clientname:'Hari Danang',
        vehiclenumber:'ABC-999DE',
        status:'Active',
        regdate:'2020-08-08',
    },
]