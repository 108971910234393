import React from 'react';
import { Card, Flex } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';
import { CalendarDropBorder } from '../../CalendarDropBorder';

const ClientlistBarChart = () => {

const chartData = {
  series: [
    {
      name: '11 April - 2024',
      data: [390, 365, 200, 260, 200, 290, 350, 170, 200, 370, 350 ],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        columnWidth: '20%',
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: [
        'United States',
        'Canada',
        'United Kingdom',
        'France',
        'Italy',
        'Spain',
        'China',
        'India',
        'Australia',
        'Brazil',
        'South Africa',
      ],
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    colors: ['#0086FF','#F56C89'],
    legend:{
      markers:{
        shape: "circle"
      }
    }   
  },
};


  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <Flex vertical>
            <ModuleTopHeading level={5} name='Clients By Location' />
          </Flex>
          <CalendarDropBorder />
        </Flex>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={300}
          className='bar-width'
        />
      </Card>
    </div>
  );
};

export { ClientlistBarChart };
