import React from 'react'
import { Col, Row } from 'antd'
import { CardComponent, ClientDashTable, ClientlistBarChart, HourlySaleBarChart } from '../../components'


const Dashboard = () => {
  
  return (
    <div>
      <Row gutter={[16,16]}>
        <Col span={24}>
          <CardComponent />
        </Col>
        <Col span={24}>
          <HourlySaleBarChart />
        </Col>
        <Col span={24}>
          <ClientlistBarChart />
        </Col>
        <Col span={24}>
          <ClientDashTable />
        </Col>
      </Row>
    </div>
  )
}

export {Dashboard}