
import { Avatar, Badge, Button, Card, Dropdown, Flex, Image, Space, Tooltip, Typography } from 'antd'
import React, { useState } from 'react'
import { SwitchAccount } from './SwitchAccount';
import { useNavigate } from 'react-router-dom';

const UserDropdown = ()=> {
  const [ switchAccount , setSwitchAccount] = useState(false)
  const navigate = useNavigate()

  const dropdownContent = (
    <Card className='text-black radius-12 shadow-d card-cs p-0'>
      <Space direction='vertical' className='w-100'>
        <Space>
          <img src="/assets/images/im-1.png" style={{width: 45,height:45,borderRadius:50}} alt="" />
          <Flex vertical gap={2}>
            <Typography.Text strong className='fs-13'>Admin</Typography.Text>
            <Typography.Text className='text-gray fs-13'>micodus@gmail.com</Typography.Text>
          </Flex>
        </Space>
        <Button className='btnsave radius-8 border0 w-100'>Logout</Button>
      </Space>
    </Card>
);
  return (
    <div>
      <Dropdown
          overlay={dropdownContent}
          trigger={['click']}
          className='margin-top'
      >
          <Button shape='circle' size='large'>
              <Image src='/assets/icons/clients.png' preview={false} width={30} />
          </Button>
      </Dropdown>
      <SwitchAccount 
          visible={switchAccount}
          onClose={()=>{setSwitchAccount(false)}}
        />
    </div>
  )
}

export {UserDropdown}