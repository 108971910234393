import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography } from "antd"
import { MyInput, MySelect } from "../../Forms"
import { deviceName, clientName, deviceType } from "../../../shared";

const { Title } = Typography;

const AddEditDevices = ({visible, onClose, edititem}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible && edititem) {
            
        } else {
            form.resetFields();
        }
    }, [visible, edititem])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={edititem?'Edit device':'Add new device'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave bg-brand'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    {edititem? 'Update' : 'Save'}
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0">DEVICE DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Device Type'
                        name="devicetype" 
                        size={'large'}
                        required
                        message='Please choose device type'
                        value={form.getFieldValue("devicetype") || ''}
                        options={deviceType}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Device Name'
                        name="devicename" 
                        size={'large'}
                        required
                        message='Please enter device name'
                        value={form.getFieldValue("devicename") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Device Number'
                        name="deviceNo" 
                        size={'large'}
                        type='number'
                        required
                        message='Please enter device number'
                        value={form.getFieldValue("deviceNo") || ''}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Client Name'
                        name="clientname" 
                        size={'large'}
                        required
                        message='Please choose client name'
                        value={form.getFieldValue("clientname") || ''}
                        options={clientName}
                    />
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddEditDevices}