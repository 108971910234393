export const invoicesData = [
    {
        key: '1',
        devicename:'Device 1',
        deviceno:'9965423499',
        licenseno:'SUB-6558789',
        Invoicenumber:'INV-993JK',
        issuedate:'2022-10-10',
        duedate:'2022-11-10',
        plan:'Monthly',
        amount:'$200',
        paymentmethod:'Bank Transfer',
        status:'Paid',
    },
    {
        key: '2',
        devicename:'Device 2',
        deviceno:'1065423488',
        licenseno:'SUB-898777',
        Invoicenumber:'INV-567HG',
        issuedate:'2022-07-10',
        duedate:'2023-07-10',
        plan:'Annually',
        amount:'$2200',
        paymentmethod:'Third Party',
        status:'Unpaid',
    },
    {
        key: '3',
        devicename:'Device 3',
        deviceno:'9765423470',
        licenseno:'SUB-1008660',
        Invoicenumber:'INV-167DF',
        issuedate:'2022-05-10',
        duedate:'2023-05-10',
        plan:'Annually',
        amount:'$2200',
        paymentmethod:'Cash',
        status:'Paid',
    },
]