import React from 'react';
import { Button, Col, Divider, Flex, Form, Image, Row, Space } from 'antd';
import { MyInput } from '../../../components';

const ChangePassword = () => {
    const [form] = Form.useForm();
    

    return (
        <Form
            form={form}
            layout="vertical"
            requiredMark={false}
        >
            <Row gutter={16}>
                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <MyInput
                        autoFocus
                        type='password'
                        name='oldPassword'
                        label="Old Password"
                        placeholder='Enter your old password'
                        required
                        message='please enter old password'
                        size='large'
                        value={form.getFieldValue("oldPassword") || ''}
                    />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <MyInput
                        name='newPassword'
                        type='password'
                        label='New Password'
                        size='large'
                        placeholder='Enter your new password'
                        required
                        message={()=>{}}
                        value={form.getFieldValue("newPassword") || ''} 
                        rules={[
                            {
                                required: true,
                            }
                        ]}
                        validator={({ getFieldValue }) => ({
                            validator: (_, value) => {
                                const reg = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*\d).{8,}$/;
                                if (!reg.test(value)) {
                                    return Promise.reject(new Error('Password should contain at least 8 characters, one uppercase letter, one number, one special character'));
                                } else {
                                    return Promise.resolve();
                                }
                            }
                        })}
                        
                    />
                </Col>
                <Col lg={{ span: 8 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <MyInput
                        name='confirmPassword'
                        type='password'
                        size='large'
                        label='Confirm Password'
                        placeholder='Enter your confirm password'
                        dependencies={['newPassword']}
                        required
                        message='Please enter confirm password'
                        value={form.getFieldValue("confirmPassword") || ''} 
                        rules={[
                            {
                                required: true,
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('newPassword') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('The password that you entered do not match!'));
                                },
                            }),
                        ]}
                        validator={({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue('newPassword') === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error('The password that you entered do not match!'));
                            },
                        })}
                    />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Space className="w-100 right flex-reverse" wrap>
                        <Button 
                            className="btncancel"
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            className='btnsave'
                            type="primary"
                            htmlType="submit"
                            onClick={()=> form.submit()}
                        >
                            Update
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export { ChangePassword };
