import React, { useState } from 'react'
import { Button,  Table, Typography, Space, Image, Col, Card, Row } from 'antd'
import { billingData } from '../../../Data';
import { ActionButton, BillingView, ModuleTopHeading } from '../../../components';


const { Text } = Typography;

const ClientBillingTable = () => {

    const [ visible, setVisible ] = useState(false)
    const [ view, setView] = useState(null)
 
    const columns = [
        {
            title: 'Device Name',
            dataIndex: 'devicename',
            sorter: (a,b) => a.devicename.localeCompare(b.devicename),
        },
        {
            title: 'Device Number',
            dataIndex: 'deviceno',
        },
        {
            title: 'License Number',
            dataIndex: 'licenseno'
        },
        {
            title: 'Invoice Number',
            dataIndex: 'Invoicenumber'
        },
        {
            title: 'Issue Date',
            dataIndex: 'issuedate',
        },
        {
            title: 'Due Date',
            dataIndex: 'duedate',
        },
        {
            title: 'Plan',
            dataIndex: 'plan',
            width:200,
            render: (plan) => {
                if (plan === 'Monthly') {
                    return <Text className='blue fs-12 badge-cs'>Monthly</Text>;
                } else {
                    return <Text className='orange fs-12 badge-cs'>Annually</Text>;
                }
            },
        },
        {
            title: 'Amount',
            dataIndex: 'amount',
        },
        {
            title: 'Payment Method',
            dataIndex: 'paymentmethod',
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                if (status === 'Paid') {
                    return <Text className='active fs-12 badge-cs'>Paid</Text>;
                } else {
                    return <Text className='inactive fs-12 badge-cs'>Unpaid</Text>;
                }
            },
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='View Billing'
                        shape="circle"
                        icon={<Image src='/assets/icons/view.png' width={18} preview={false} />}
                        className='border0 bg-transparent'
                        onClick={()=> {setVisible(true);setView(row)}}
                    />
                </Space>
            ),
        },
    ];
    return (
        <React.Fragment>
            <Card className='shadow-d radius-12 border0 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Col span={24}>
                            <ModuleTopHeading level={5} name='Billing'/>
                        </Col>
                    </Col>  
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns}
                            dataSource={billingData}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            scroll={{ x: 1600 }}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
            <BillingView 
                visible={visible}
                view={view}
                onClose={()=>{setVisible(false);setView(null)}}
            />
        </React.Fragment>
    )
}

export { ClientBillingTable }