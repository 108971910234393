import React, { useState } from 'react'
import { Modal, Space, Button, Form, Row, Col, Typography, Image } from 'antd'
import { MySelect } from '../../Forms';

const { Title } = Typography

const TicketStatus = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()
    
  return (
    <div>
        <Modal 
            width={500} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            centered 
            footer={
                <Space style={{justifyContent:"center"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Confirm
                    </Button>
                </Space>
            }
        >
            <Space className='text-center pt-3' align='center' size={15} direction='vertical'>
                <Image 
                    src='/assets/icons/status.png' width={50} preview={false} 
                />
                <Title level={5} className='my-0'>
                    Ticket Status
                </Title>
            </Space>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                requiredMark={false}
                className='content-modal pb-2'
            >
                <Row>
                    <Col span={24}>
                        <MySelect
                            label= 'Status'
                            name="status" 
                            size={'large'}
                            value={form.getFieldValue("status") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Resolved'
                                },
                                {
                                    key: 2,
                                    name: 'In Progress'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {TicketStatus}