import React from 'react'
import { Button,  Table, Typography, Space, Image, Col, Card, Row } from 'antd'
import { clientDeviceData } from '../../../Data';
import { ActionButton, ModuleTopHeading } from '../../../components';
import { useNavigate, useParams } from 'react-router-dom';

const { Text } = Typography;

const Clientdevicetable = () => {

    const navigate = useNavigate()
    const { clientId } = useParams()

    const columns = [
        {
            title: 'Device Type',
            dataIndex: 'devicetype',
        },
        {
            title: 'Device Name',
            dataIndex: 'devicename',
            sorter: (a,b) => a.devicename.localeCompare(b.devicename),
        },
        {
            title: 'Device Number',
            dataIndex: 'devicenumber'
        },
        {
            title: 'Vehicle Number',
            dataIndex: 'vehiclenumber'
        },
        {
            title: 'Current Plan',
            dataIndex: 'plan',
            render: (status) => {
              if (status === 'Monthly') {
                  return <Text className='blue fs-12 badge-cs'>Monthly</Text>;
              } else{
                  return <Text className='orange fs-12 badge-cs'>Annually</Text>;
              } 
          },
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                if (status === 'Active') {
                    return <Text className='active fs-12 badge-cs'>Active</Text>;
                } else {
                    return <Text className='inactive fs-12 badge-cs'>Inactive</Text>;
                }
            },
        },
        {
            title: 'Registeration Date',
            dataIndex: 'registerationdate'
        },
        {
            title: <Text>Action</Text>,
            key: 'action',
            fixed: 'right',
            width: 100,
            render: (_, row) => (
                <Space>
                    <ActionButton
                        title='Track Current Location'
                        shape="circle"
                        icon={<Image src='/assets/icons/location.png' width={18} preview={false} />}
                        className='border0 bg-transparent'
                        onClick={()=> navigate(`/clients/clientview/${clientId}/${row?.key}`)}
                    />
                    <ActionButton
                        title='License Details'
                        shape="circle"
                        icon={<Image src='/assets/icons/license-detail.png' width={18} preview={false} />}
                        className='border0 bg-transparent'
                        onClick={()=> navigate(`/license/licenseview/${row?.key}`)}
                    />
                </Space>
            ),
        },
    ];
    return (
        <React.Fragment>
            <Card className='shadow-d radius-12 border0 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Col span={24}>
                            <ModuleTopHeading level={5} name='Devices'/>
                        </Col>
                    </Col>  
                    <Col span={24}>
                        <Table
                            size='large'
                            columns={columns}
                            dataSource={clientDeviceData}
                            className='table-dark pagination'
                            showSorterTooltip={false}
                            scroll={{ x: 1600 }}
                            pagination={{
                                hideOnSinglePage: true,
                                total: 12,
                                // pageSize: pagination?.pageSize,
                                // defaultPageSize: pagination?.pageSize,
                                // current: pagination?.pageNo,
                                // size: "default",
                                // pageSizeOptions: ['10', '20', '50', '100'],
                                // onChange: (pageNo, pageSize) => call(pageNo, pageSize),
                                showTotal: (total) => <Button className='brand-bg border0 text-white'>Total: {total}</Button>,
                            }}
                        />
                    </Col>
                </Row>
            </Card>
        </React.Fragment>
    )
}

export { Clientdevicetable }