export const clientData = [
    {
        key:'1',
        clientname:'Shujat ',
        accountnumber:'23426',
        phonenumber:'(209) 555-0104',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
    {
        key:'2',
        clientname:'Basit ',
        accountnumber:'23426',
        phonenumber:'(209) 555-0104',
        country:'India',
        deviceregistered:'3',
        status:'Inactive',
        registereddata:'10/20/2024',     
    },
    {
        key:'3',
        clientname:'hanan ',
        accountnumber:'23426',
        phonenumber:'(209) 555-0104',
        country:'India',
        deviceregistered:'1',
        status:'Active',
        registereddata:'10/20/2024',     
    },
]