import React from 'react';
import { Button, Card, Col, Divider, Flex, Form, Image, Row, Space } from 'antd';
import { MyInput } from '../../../components';

const ProfileSetting = () => {
    const [form] = Form.useForm();
    

    return (
        <Form
            form={form}
            layout="vertical"
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Flex justify='center' className='my-4'>
                        <Image src='/assets/images/im-1.png' width={150} height={90} style={{objectFit:'cover'}} className='radius-12'/>
                    </Flex>
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <MyInput
                        name="name"
                        label="Name"
                        placeholder="Super Admin"
                        size='large'
                        value={form.getFieldValue("name") || ''}
                    />
                </Col>
                <Col lg={{ span: 12 }} md={{ span: 12 }} sm={{ span: 24 }} xs={{ span: 24 }}>
                    <MyInput
                        name="email"
                        label="Email Address"
                        placeholder="shafrah@gmail.com"
                        size='large'
                        value={form.getFieldValue("email") || ''}
                    />
                </Col>
                <Col span={24}>
                    <Divider />
                </Col>
                <Col span={24}>
                    <Space className="w-100 right">
                        <Button 
                            className="btncancel"
                        >
                            Cancel
                        </Button>
                        <Button  
                            block
                            className='btnsave'
                            type="primary"
                            htmlType="submit"
                            onClick={()=> form.submit()}
                        >
                            Update
                        </Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    );
};

export { ProfileSetting };
