import React from 'react';
import { Card, Flex } from 'antd';
import ReactApexChart from 'react-apexcharts';
import { ModuleTopHeading } from '../../PageComponents';
import { CalendarDropBorder } from '../../CalendarDropBorder';


const HourlySaleBarChart = () => {

const chartData = {
  series: [
    {
      name: '11 April - 2024',
      data: [280, 265, 400, 110, 250, 200, 150, 350, 170, 200, 370, 350,280,200, 370, 350,280, 370,],
    },
  ],
  options: {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        borderRadius:5,
        columnWidth: '30%',
      }
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
      width: 2,
    },
    xaxis: {
      categories: [
        '12 am',
        '01 am',
        '02 am',
        '03 am',
        '04 am',
        '05 am',
        '06 am',
        '07 am',
        '08 am',
        '09 am',
        '10 am',
        '11 am',
        '12 am',
        '01 am',
        '02 am',
        '03 am',
        '04 am',
        '05 am',
      ],
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    yaxis: {
      min: 0,
      max: 400,
      tickAmount: 5,
      labels: {
        style: {
          colors: '#000',
        },
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      show: false,
    },
    colors: ['#529F16'],
    legend:{
      markers:{
        shape: "circle"
      }
    }   
  },
};

  return (
    <div>
      <Card className='shadow-d radius-12 card-cs'>
        <Flex justify='space-between' align='center' wrap gap={10}>
          <Flex vertical>
            <ModuleTopHeading level={5} name='Sales Analysis' />
            <ModuleTopHeading level={4} name='SAR 45,881' />
          </Flex>
          <CalendarDropBorder />
        </Flex>
        <ReactApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={300}
          className='bar-width'
        />
      </Card>
    </div>
  );
};

export { HourlySaleBarChart };
