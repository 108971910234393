import React, { useEffect, useState } from "react"
import { Drawer, Form, Row, Col ,Button, Space, Typography } from "antd"
import { MyDatepicker, MyInput, MySelect, SingleFileUpload, StoreSingleFileUpload } from "../../Forms"
import { deviceName, clientName } from "../../../shared";

const { Title } = Typography;

const AddLicense = ({visible, onClose, renewstate}) => {
  
    const [form] = Form.useForm()
    const [ loading, setLoading ] = useState(false)  
  
    useEffect(() => {
        if (visible) {
            
        } else {
            form.resetFields();
        }
    }, [visible])

    const closeDrawer= ()=>{
     
        form.resetFields()
        onClose()
    }

  return (
    <Drawer
        title={renewstate ? 'Renew license' : 'Add new license'}
        onClose={closeDrawer}
        className="drawer"
        open={visible}
        width={600}
        footer={
            <Space className="w-100 right">
                <Button 
                    onClick={closeDrawer}
                    className="btncancel"
                >
                    Cancel
                </Button>
                <Button  
                    block
                    className='btnsave bg-brand'
                    type="primary"
                    htmlType="submit"
                    loading={loading}
                    onClick={()=> form.submit()}
                >
                    Save
                </Button>
            </Space>
        }
    >
        <Form
            form={form}
            layout="vertical"
            initialValues={true}
            // onFinish={onFinish}
        >
            <Row gutter={16}>
                <Col span={24}>
                    <Title level={5} className="mb-3 mt-0">LICENSE  DETAILS</Title>
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Account Number'
                        name="accountNo" 
                        size={'large'}
                        type='number'
                        required
                        message='Please enter account number'
                        value={form.getFieldValue("accountNo") || ''}
                        disabled={renewstate}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Client Name'
                        name="clientname" 
                        size={'large'}
                        value={form.getFieldValue("clientname") || ''}
                        disabled={renewstate}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Device Number'
                        name="deviceNo" 
                        size={'large'}
                        type='number'
                        required
                        message='Please enter device number'
                        value={form.getFieldValue("deviceNo") || ''}
                        disabled={renewstate}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyInput
                        label= 'Device Type'
                        name="devicetype" 
                        size={'large'}
                        required
                        message='Please enter device type'
                        value={form.getFieldValue("devicetype") || ''}
                        disabled={renewstate}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Plan'
                        name="clientname" 
                        size={'large'}
                        required
                        message='Please choose client name'
                        value={form.getFieldValue("clientname") || ''}
                        options={[
                            {
                                id: 1,
                                name:'Monthly'
                            },
                            {
                                id: 2,
                                name:'Annually'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MyDatepicker
                        datePicker
                        label= 'Issue Date'
                        name="issuedate" 
                        size={'large'}
                        required
                        message='Please enter issue date'
                        value={form.getFieldValue("issuedate") || ''}
                        className='border-gray'
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Payment Method'
                        name="paymentmethod" 
                        size={'large'}
                        required
                        message='Please choose payment method'
                        value={form.getFieldValue("paymentmethod") || ''}
                        options={[
                            {
                                id: 1,
                                name:'Cash'
                            },
                            {
                                id: 2,
                                name:'Bank Transfer'
                            },
                        ]}
                    />
                </Col>
                <Col lg={{span: 12}} md={{span: 24}} xs={{span: 24}} sm={{span: 24}}>
                    <MySelect
                        label= 'Payment Status'
                        name="paymentstatus" 
                        size={'large'}
                        required
                        message='Please choose payment status'
                        value={form.getFieldValue("paymentstatus") || ''}
                        options={[
                            {
                                id: 1,
                                name:'Paid'
                            },
                            {
                                id: 2,
                                name:'Unpaid'
                            },
                        ]}
                    />
                </Col>
                <Col span={24}>
                    <SingleFileUpload label={'Receipt'} title={'Upload Receipt'} form={form}/>
                </Col>
            </Row>
        </Form>
    </Drawer>
  )
}

export {AddLicense}