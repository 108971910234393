import React from 'react';
import { DatePicker, Image } from 'antd';
import './index.css'; 
import moment from 'moment';

const CalendarDropBorder = ({presets}) => {
    const onChange = (date, dateString) => {
        console.log(date, dateString);
    };

    return (
        <div>
            <DatePicker
                presets={presets}
                className="custom-date-border mbl-btn"
                suffixIcon={<Image src='/assets/icons/calendar-d.png' width={20} preview={false} />}
                onChange={onChange}
                allowClear={false}
                format="MMMM DD, YYYY"
                defaultValue={''}
            />
        </div>
    );
};

export { CalendarDropBorder };
