import React, { useState } from 'react'
import { Modal, Space, Typography, Button, Image } from 'antd'
import { apiCalls } from '../../../shared/Apis';
const { Title, Text } = Typography
const DownloadConfirmModal = ({visible,onClose, receipt, download}) => {
    
    const [ loading, setLoading ] = useState(false)
    
    // const DeleteProductApi = async() => {
    //     setLoading(true)
    //     try{
    //         const result = await apiCalls.vendorApi.DeleteProductApi(deletevendor?.id)
    //         setLoading(false)
    //         onClose();
    //         getVendorApplications();
    //     } catch (error){
    //         console.error("Error deleting data",error)
    //     }
    // }

    
    
  return (
    <div>
        <Modal width={500} className='shadow-c'  open={visible} onOk={onClose} onCancel={onClose} centered footer={null}>
            <Space className='w-100 py-2 text-center' align='center' size={10} direction='vertical'>
                <Image src='/assets/icons/download-ic.png' width={50} preview={false} />
                <Title level={4} className='my-0'>Alert</Title>
                <Text className='text-center'>
                    {
                        download ? (
                            <>
                                Are you sure you want to download the image of expense #<strong>"{ download ? download.name : ''}"</strong>?
                            </>
                        ) : (
                            <>
                                Are you sure you want to download the receipt against reservation # <strong>"{ receipt ? receipt.name : ''}"</strong>?
                            </>
                        )
                    }     
                </Text>
                <Space style={{justifyContent:"end",marginTop:'15px'}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave brand-bg text-white'
                        danger
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Download
                    </Button>
                </Space>
            </Space>
        </Modal>
    </div>
  )
}

export {DownloadConfirmModal}