export const helpDesk = [
    {
        key: '1',
        ticketno:'MV810G',
        clientname:'Susan Sumanggih',
        phonenumber:'(209) 555-0104',
        category:'Technical',
        priority:'High',
        status:'Resolved'
    },
    {
        key: '2',
        ticketno:'MV930',
        clientname:'Lukman Farhan',
        phonenumber:'(209) 555-0104',
        category:'Technical',
        priority:'Medium',
        status:'In Progress'
    },
    {
        key: '3',
        ticketno:'MV900G',
        clientname:'Hari Danang',
        phonenumber:'(209) 555-0104',
        category:'Technical',
        priority:'Urgent',
        status:'Resolved'
    },
    {
        key: '4',
        ticketno:'MV1000D',
        clientname:'Rina Karina',
        phonenumber:'(209) 555-0104',
        category:'Technical',
        priority:'Low',
        status:'Pending'
    }
]