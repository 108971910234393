import React, { useState } from 'react'
import { Modal, Space, Button, Row, Col, Typography, Table, Flex, Image, Form, Upload, message } from 'antd'
import { CloseOutlined } from '@ant-design/icons'
import { MySelect } from '../../Forms'

const { Text } = Typography
const { Dragger } = Upload
const InvoiceChangeStatus = ({visible,onClose}) => {
    
    const [ loading, setLoading ] = useState(false)
    const [form] = Form.useForm()

    const props = {
        name: 'file',
        action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
        headers: {
          authorization: 'authorization-text',
        },
        onChange(info) {
          if (info.file.status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (info.file.status === 'done') {
            message.success(`${info.file.name} file uploaded successfully`);
          } else if (info.file.status === 'error') {
            message.error(`${info.file.name} file upload failed.`);
          }
        },
      };

  return (
    <div>
        <Modal 
            width={600} 
            title={null} 
            className='shadow-c modal-cs'  
            open={visible} 
            onOk={onClose} 
            onCancel={onClose} 
            closeIcon={false}
            centered 
            footer={
                <Space style={{justifyContent:"end"}} className="w-100">
                    <Button
                        onClick={onClose}
                        className='btncancel'
                    >
                        Cancel
                    </Button>
                    <Button  
                        block
                        type="primary"
                        className='btnsave text-white'
                        loading={loading}
                        onClick={()=>{
                            // DeleteProductApi();  
                        }}
                        
                    >
                        Save
                    </Button>
                </Space>
            }
        >
            <Flex align='center' justify='space-between' className='header-modal'>
                <Text strong>Payment Status</Text>
                <Button className='bg-transparent border0 p-0 ' onClick={onClose}>
                    <CloseOutlined  className='text-gray'/>
                </Button>
            </Flex>
            <Form
                form={form}
                layout="vertical"
                initialValues={true}
                // onFinish={onFinish}
                className='content-modal pb-2'
            >
                <Row>
                    <Col lg={{ span: 24 }} md={{ span: 24 }} xs={{ span: 24 }} sm={{ span: 24 }}>
                        <Form.Item name='contract' >
                            <div className='width-drag'>
                                <Dragger {...props} className="dragcs">
                                    <p className="ant-upload-drag-icon mb-0">
                                        <Image src='/assets/icons/plus.png' width={'41.6px'} preview={false} />
                                    </p>
                                    <Typography.Text className="fs-14 fw-500">Upload Receipt</Typography.Text>
                                </Dragger>
                            </div>
                            {/* {
                                editproperty &&
                                <Space direction='vertical' size={10}>
                                    {
                                        geteditdata?.medical_report?.map((data,index)=>
                                            <div className='viewDownload space-between-align' key={index}>
                                                <Typography.Text className='gray-text fs-12'>
                                                    {
                                                        data?.name
                                                    }
                                                </Typography.Text>
                                                <ActionButton
                                                    title='Delete'
                                                    icon={<DeleteOutlined className='danger-color' />}
                                                    onClick={()=>{setDeletePatientDoc(true);setDeletePatMed({id:data?.id, name:data?.name})}}
                                                    type='ghost'
                                                />
                                            </div>
                                        )
                                    }
                                </Space>
                                
                            } */}
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <MySelect
                            label= 'Status'
                            name="status" 
                            size={'large'}
                            required
                            message={'Please choose status'}
                            value={form.getFieldValue("status") || ''}
                            options={[
                                {
                                    key: 1,
                                    name: 'Paid'
                                },
                            ]}
                        />
                    </Col>
                </Row>
            </Form>
        </Modal>
    </div>
  )
}

export {InvoiceChangeStatus}