export const clientDeviceData = [
    {
        key: '1',
        devicetype:'MV810G',
        devicename:'Device 1',
        devicenumber:'INV-993JK',
        vehiclename:'Eicher Pro 3008',
        vehiclenumber:'ABC-999DE',
        plan:'Monthly',
        status:'Active',
        registerationdate:'11/11/2024'
    },
    {
        key: '2',
        devicetype:'MV930',
        devicename:'Device 2',
        devicenumber:'INV-567HG',
        vehiclename:'LPT 1618',
        vehiclenumber:'DEW-999DE',
        plan:'Annually',
        status:'Active',
        registerationdate:'20/11/2024'
    },
    {
        key: '3',
        devicetype:'MV710G',
        devicename:'Device 3',
        devicenumber:'IVD-567HG',
        vehiclename:'Eicher 10.80',
        vehiclenumber:'UYT-999DE',
        plan:'Annually',
        status:'Inactive',
        registerationdate:'25/11/2024'
    },
]