import React from 'react'
import { Breadcrumb, Button, Card, Col, Flex, Row, Space, Table, Typography } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { clientData, clientDeviceData } from '../../../Data';
import { Clientlocation } from './Clientlocation';

const { Text } = Typography
const Clientlocationview = () => {
    let { locationId, clientId } = useParams()
    const navigate = useNavigate()
    const clientDevice = clientData.find((mod)=>mod.key === clientId)  
    const locationdetail = clientDeviceData.find((dat)=>dat.key === locationId)
      

    const columns = [
        {
            title: 'Status',
            dataIndex: 'status',
            width: 150,
        },
        {
            title: 'Detail',
            dataIndex: 'detail',
            width: 150,
        },
    ];
    const data = [
        {
            key: '1',
            status: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Current Location</Text>
                <Text>1937 - 1987, Sneic Highway, GA 30098</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Fuel Consumption</Text>
                <Text>10 mpg</Text>
            </Space>,
        },
        {
            key: '2',
            status: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Speed</Text>
                <Text>80 km/h</Text>
            </Space>,
            detail: <Space direction='vertical' size={2}>
                <Text className='text-gray'>Fuel Efficiency</Text>
                <Text>
                    Average
                </Text>
            </Space>,
        },
    ];

    return (
        <div>
            <Breadcrumb className='mb-3'
                separator=">"
                items={[
                    {
                      title:<Text className='cursor text-gray' onClick={()=>navigate('/clients')}>Clients</Text>,

                    },
                    {
                      title:<Text className='cursor text-gray' onClick={()=>navigate(-1)}>{clientDevice?.clientname}</Text>,
                    },
                    {
                      title:<Text className='fw-500'>{locationdetail?.devicename}</Text>,
                    },
                ]}
            />
            <Card className='shadow-d radius-12 mb-3'>
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <Space>
                            <Button className='border0 p-0 bg-transparent' onClick={()=>navigate(-1)}>
                                <ArrowLeftOutlined />
                            </Button>
                            <Text className='fw-600 fs-16'>{locationdetail?.devicename}</Text>
                        </Space>
                    </Col> 
                    <Col span={24}>
                        <Clientlocation />
                    </Col>
                    <Col span={24}>
                        <Card className='radius-12'>
                            <Flex vertical gap={20}>
                                <Text className='fw-500'>Status</Text>
                                <Table 
                                    size='large'
                                    columns={columns} 
                                    dataSource={data} 
                                    scroll={{x: 1000}}
                                    className='pagination'
                                    showSorterTooltip={false}
                                    rowHoverable={false}
                                    showHeader={false}
                                    pagination={false}
                                />
                            </Flex>
                        </Card>
                    </Col>
                </Row>
            </Card>
        </div>
    )
}

export { Clientlocationview }
