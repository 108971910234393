import React from 'react';
import { InboxOutlined, PlusOutlined } from '@ant-design/icons';
import { message as Mesage, Upload, Form, Typography} from 'antd';
const { Dragger } = Upload;

const SingleFileUpload = ({label, name, required, message, form, title}) => {
    const props = {
        name: 'file',
        multiple: false,
        action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',
        onChange(info) {
          const { status } = info.file;
          if (status !== 'uploading') {
            console.log(info.file, info.fileList);
          }
          if (status === 'done') {
            Mesage.success(`${info.file.name} file uploaded successfully.`);
            form.setFieldsValue({...form.getFieldsValue, [name]: info?.file?.originFileObj || null})
            
          } else if (status === 'error') {
            // Mesage.error(`${info.file.name} file upload failed.`);
            form.setFieldsValue({...form.getFieldsValue, [name]: info?.file?.originFileObj || null})
          }
        },
        onDrop(e) {
          console.log('Dropped files', e.dataTransfer.files);
        }
    }
    return (
        <Form.Item
       
            name={name}
            label={<Typography.Text strong  className='label'>{label}</Typography.Text>}
            rules={[
                {
                required,
                message,
                },
            ]}
        >
            <Dragger {...props} className='upload-d'>
        <p className="my-2">
          <PlusOutlined style={{fontSize: 24}} /> 
        </p>
        {/* <p className="ant-upload m-0 py-1">Click or drag file to this area to upload</p> */}
        <p className="ant-upload m-0 py-1">
          {title}
        </p>
      </Dragger>
        </Form.Item>
    )
}
export {SingleFileUpload}